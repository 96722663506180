import { UserOutlined, WechatOutlined } from '@ant-design/icons';
import { Avatar, Badge, Image, Space } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';
import SignOutButton from './SignOutButton';

function Header({ user }) {
    return (
        <div className="topbar">
            <div className="topbarWrapper">
                <div className="topLeft">
                    {/* <Badge count={'Beta'} offset={[30, 5]} style={{ backgroundColor: 'green' }}> */}
                    <div className="logo">
                        {/* <Space> 
                            <Image
                                height={25}
                                src='../assets/dupont_logo.png'
                                preview={false}
                            />
                            <span style={{ color: '#fff', marginLeft:50 }}>
                                Dupont GenAI-Chat
                            </span> 
                        </Space> */}

                        <span style={{ color: '#fff' }}>
                            DuPont SmartChat
                        </span>
                    </div>
                    {/* </Badge> */}
                </div>
                <div className="topRight">

                    {/* <div className="topbarIconContainer">
                        <i className="bx bx-message" style={{ fontSize: '1.2em', marginRight: 5 }} />
                        <Link to="/"><span>Chat</span></Link>
                    </div> */}

                    <div className="topbarIconContainer">
                        <i className="bx bx-support" style={{ fontSize: '1.2em', marginRight: 5 }} />
                        <Link to="mailto:DL-R-GLOBAL-genaichat-support@dupont.com?subject=DuPont SmartChat Support&body="><span>Support</span></Link>
                    </div>

                    <div className="topbarIconContainer">
                        <i className="bx bx-bulb" style={{ fontSize: '1.5rem' }} />
                        <Link target="_blank" to="https://dupont.sharepoint.com/sites/ITMatters/SitePages/Generative-AI-at-DuPont.aspx"><span>Learn more & submit ideas</span></Link>
                    </div>

                    <div className="topbarIconContainer">
                        <Space>
                            {user.avatar === null ?
                                <Avatar
                                    style={{ backgroundColor: "#fde3cf", color: "#f56a00", borderColor: "#E1E1E1" }}
                                    src={null}
                                >
                                    <span style={{ fontWeight: 700, fontSize: 12 }}>{user.firstname[0]}{user.lastname[0]}</span>
                                </Avatar>

                                : <Avatar
                                    // shape="square"
                                    style={{ borderColor: "#E1E1E1" }}
                                    src={user.avatar}
                                />
                            }

                            <span>{user.fullname}</span>
                        </Space>
                    </div>

                    {/* <img src="https://www.dupont.com/content/dam/dupont/global-assets/logos/DuPont_logo_140x55_rgb1.png" className="topAvatar" /> */}


                    <div className="topbarIconContainer">
                        <SignOutButton />
                    </div>

                    <div className="topbarIconContainer">
                        <Image
                            height={24}
                            src='../assets/DuPont_tm_w.svg'
                            preview={false}
                        />
                    </div>

                </div>
            </div>
        </div >
    );
}

export default Header