import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Undermaintenance from './UndermaintenancePage';
import DataProvider from './redux/store'

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { authConfig } from "./config/msal/authConfig";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(authConfig);

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */

const root = ReactDOM.createRoot(document.getElementById('root'));
//console.log('process.env.REACT_APP_IS_UNDER_MAINTENANCE>>>>>>>>>>>>>>>',process.env.REACT_APP_IS_UNDER_MAINTENANCE)
if (process.env.REACT_APP_IS_UNDER_MAINTENANCE==='true') {
  //console.log("Under maintenance is true");
  root.render(
          <Undermaintenance />
    );  
} else {
  //console.log("Under maintenance is false");
  root.render(
    <MsalProvider instance={msalInstance}>
        <DataProvider>
          <App />
        </DataProvider>
      </MsalProvider>
    );  
}
