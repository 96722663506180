import React from 'react'
import { useMsal } from "@azure/msal-react";
import { Button, Space } from 'antd';

const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = (logoutType) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }
    return (
        <Space size={[0,0]}>
            <i className="bx bx-log-out" style={{ fontSize: '1.5rem', marginRight: 5 }} />
            <span style={{ fontSize: 16 }} onClick={() => handleLogout("popup")}>
                Sign out
            </span>
        </Space>
    )
}

export default SignOutButton