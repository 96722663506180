import { UserOutlined, WechatOutlined } from '@ant-design/icons';
  import { Avatar, Badge, Image, Space } from 'antd';
  import React from 'react'
  import Marquee from 'react-fast-marquee';
import { Alert } from 'antd';
  //import { Link } from 'react-router-dom';
  //import SignOutButton from './SignOutButton';
  import { useNavigate, useLocation } from 'react-router-dom';




  
  function TabNavigator() {
    

    const location = useLocation();
    const navigate = useNavigate();
       let activeTab = "/";
    
      const handleImageGeneratorClick = () => {
          navigate('/image-generator');

      }
    
      const handleHomeClick = () => {
          navigate('/');
      }
  
      if (location.pathname.startsWith("/image-generator")) {
        activeTab = "/image-generator";
      } 
    
      return (
        <div className="tabNavigatorBar">
          <ul className="pageTabs">
          <li className={activeTab === "/" ? "pageTab activePageTab" : "pageTab"} onClick={handleHomeClick}>Home</li>
             <li className={activeTab === "/image-generator" ? "pageTab activePageTab" : "pageTab"} onClick={handleImageGeneratorClick}>Image Generator</li>
          </ul>
          <div style={{width: 15}}/>
          {/* <Alert className="marquee"
    banner
    message={
      <Marquee pauseOnHover gradient={false}>
        DuPont SmartChat now supports multiple file uploads to the same conversation as well as an increased file size limit of 50 MB.       
      </Marquee>
    }
    type="info" showIcon
  /> */}
        </div>
        
      );
    }
    
    export default TabNavigator;