import React, { useRef } from 'react'
import { Image, Space, Collapse } from 'antd'
// import moment from 'moment-timezone';

const { Panel } = Collapse

function UndermaintenancePage() {
  return (
    <>
      <div style={{ position:'fixed', top:'25%', textAlign:'center',width:'100%',color:'red' }}>
          <Image
              src='../assets/dupont_logo.png'
              preview={false}
          />
      </div>
      <div style={{ position:'fixed', top:'35%', textAlign:'center',width:'100%',color:'red' }}><h1>DuPont SmartChat</h1></div>
      <div style={{ position:'fixed', top:'50%', textAlign:'center',width:'100%',color:'red' }}><p>Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment. If you need to, you can always <a href="https://dupont.sharepoint.com/sites/ITMatters/SitePages/Generative-AI-at-DuPont.aspx" target='_blank' rel="noreferrer" style={{ color:'blue' }}>contact us</a>, otherwise we&rsquo;ll be back online shortly!</p></div>
    </>
  )
}

export default UndermaintenancePage
