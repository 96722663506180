const initialState = {
    sessionId: -1,
    prompt: '',
    dataObj: [],
    numVal: 4,
    isGen: true,
    selectAll: false,
    limFlag: false
}



const dalleReducer = (state = initialState, action) => {
    // console.log(action.type)
    switch (action.type) {
        case "SET_SESSION":{
            return {
                ...state,
                sessionId: action.payload
            };
        }
        case "SET_SELECTALL": {
            return {
                ...state,
                selectAll: action.payload
            };
        }
        case "SET_ISGEN": {
            return {
                ...state,
                isGen: action.payload
            }; 
        }
        case "SET_QTY": {
            return {
                ...state,
                numVal: action.payload
            };
        }
        case "SET_PROMPT": {
            return {
                ...state,
                prompt: action.payload
            };
        }
        case "SET_CARDLIST": {
                return {
                    ...state,
                    dataObj: action.payload
                }

            }
        case "SET_LIMFLAG": {
                return {
                    ...state,
                    limFlag: action.payload
                }

            }
                             
        default:
            return state;
    }
}

export default dalleReducer