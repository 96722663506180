import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from 'react-router-dom';

import { useMsal } from "@azure/msal-react";
import { callMsGraph } from "./config/msal/graph";
import { loginRequest } from "./config/msal/authConfig";

import { useSelector, useDispatch } from 'react-redux';
import { setUserProfile } from "./redux/actions/authActions";

import Header from './components/Header';
import TabNavigator from './components/TabNavigator';
import Login from "./components/Login";
import Loading from "./components/Loading";
import AppRoutes from "./route/AppRoutes";
import axios from "axios";
import { Image, Space } from "antd";
import './App.css';
import moment from 'moment';
import SessionTimeout from "./components/SessionTimeout";

function App() {
  const [accessToken, setAccessToken] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [hasAccess, setHasAccess] = useState(true);
  const [authInProgress, setAuthInProgress] = useState(false);
  const [tokenExpiresOn, setTokenExpiresOn] = useState(false);
const [activeTab, setActiveTab] = useState(localStorage.getItem("activeTab") || "/");

  const {
    auth: {
      isAuthenticated,
      user
    }
  } = useSelector(state => state);

  const dispatch = useDispatch();
  const { instance, inProgress, accounts } = useMsal();

  useEffect(() => {
    setAuthInProgress(true);
    const getUserProfile = async () => {
      try {
        // Silently acquires an access token which is then attached to a request for MS Graph 
        await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0]
        }).then((response) => {

          setAccessToken(response.accessToken);
          setTokenExpiresOn(response.expiresOn);
          callMsGraph(response.accessToken).then(response => setGraphData(response));
        });
      } catch (error) {
        setAuthInProgress(false);
      }
    }
    getUserProfile();
  }, [instance, inProgress, accounts])

  useEffect(() => {
    const checkUserAccess = async () => {
      try {

      } catch (error) {

      }
      if (graphData) {
        

        // console.log('<<<<<<<<<<<<<accounts<<<<<<<' + JSON.stringify(accounts))
        // console.log('<<<<<<<<<<<<<graphdata<<<<<<<' + JSON.stringify(graphData))
        const { displayName, userPrincipalName, givenName, surname } = graphData;

        const graphEndpoint = "https://graph.microsoft.com/v1.0/me/photo/$value";


        let userAvatar = null;
        window.URL.revokeObjectURL(userAvatar)
        try {
          const response = await axios(graphEndpoint, {
            headers: { Authorization: `Bearer ${accessToken}`, },
            responseType: "blob"
          })

          if (response.status === 200)
            userAvatar = window.URL.createObjectURL(response.data);

        } catch (error) {
          userAvatar = null;
        }

        const userProfile = {
          isAuthenticated: true,
          user: {
            fullname: displayName,
            email: userPrincipalName,
            firstname: givenName,
            lastname: surname,
            avatar: userAvatar
          },
          token: accessToken
        }

        //window.URL.revokeObjectURL(userAvtar)
        dispatch(setUserProfile(userProfile))
        setAuthInProgress(false);
      }
      else {
        setHasAccess(false)
        setAuthInProgress(false);
      }
    }
    checkUserAccess();
  }, [graphData]);

  return (
    <>
      {!authInProgress &&
        <Router>
          {!isAuthenticated && <Login />}
          {isAuthenticated &&
            <div className="layout">
              <Header user={user && user} />
              {/* <TabNavigator activeTab={activeTab} setActiveTab={setActiveTab} /> */}
                <AppRoutes />

              <SessionTimeout />
            </div>
          }
        </Router>
      }

      {authInProgress && <div style={{ position: 'absolute', top: '45%', left: '32%', fontSize: 20 }}>
        <div>
          <div style={{ marginLeft: '40%', marginBottom: 15 }}>
            <Image
              width={50}
              height={40}
              src="../assets/message-bot-solid.png"
              preview={false}
            />
          </div>
          <Space>
            <h4>
              Just a moment, we are getting things ready for you
            </h4>
            <Loading style={{ width: 100, height: 30, margin: '5px 0px' }} />
          </Space>
        </div>

      </div>}
    </>
  );
}

export default App; 